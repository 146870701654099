<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
/deep/ .ant-table-tbody tr {
  background-color: #fff;
}
.table-page-search-submitButtons .ant-btn-primary{
  margin-left: 24px;
}
</style>
<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="12">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderSn" placeholder="" />
            </a-form-item>
          </a-col>

        
          <a-col :md="6" :sm="12">
            <a-form-item label="类型">
              <a-select placeholder="全部" v-model="queryParam.auditState">
                <a-select-option :key="item.enumValue" v-for="item in auditStateData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

           <a-col>
                <span class="table-page-search-submitButtons">
                    <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                    <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
                </span>
          </a-col>
        </a-row>       
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="orderSn"
      :columns="columns"
      :data="loadData"
      :alert="options.alert"
      :page-size="10"
      :scroll="{ x: 'max-content' }"
    >
      <span slot="luxian" slot-scope="text, record">
        <template>
          <span>{{ record.shipperGeoInfo.cityName }} 至 {{ record.receiverGeoInfo.cityName }}</span>
        </template>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)" v-action:orderDetailButton>详情</a>         
        </template>
      </span>
    </s-table>

  </div>
</template>

<script>
import { STable } from '@/components'
import { getSecondaryOrderConfirmList } from '@/api/order'

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {      
      auditStateData: [],          
      orderId: 0,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          align: 'center',
          dataIndex: 'orderSn',
          width: '200px',
          fixed: 'left',
        },
        {
          title: '下单时间',
          align: 'center',
          dataIndex: 'createDate',
          width: '20%'
        },
        {
          title: '物品名称',
          align: 'center',
          dataIndex: 'goodsName',
          width: '20%',         
        },
         {
          title: '状态',
          align: 'center',
          dataIndex: 'auditStateSecVal',
          width: '20%',         
        },
        {
          title: '路线',
          align: 'center',
          dataIndex: 'luxian',
          width: '20%',
          scopedSlots: { customRender: 'luxian' },
        },
        {
          title: '发起人',
          align: 'center',
          dataIndex: 'createName',
          width: '10%'
        },          
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],

      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        return getSecondaryOrderConfirmList(Object.assign(parameter, this.queryParam)).then((res) => {          
          if(res.code == 0){
             this.auditStateData   = res.result.stateList
             return res.result
          }else{
             this.$notification.error({
              message: '加载失败',
              description: res.message,
            })
          }

        })
      },

      options: {

      },

    }
  },
  filters: {

  },
  created() {

  },
  methods: {   
    handleDetail(record) {
      this.$router.push({ name: 'OrderDetailSecondView', query: { orderId: record.orderId} })
    },
  },
}
</script>
